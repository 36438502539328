import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Riverside Anglers Retreat
			</title>
			<meta name={"description"} content={"Crafted Libations, Unforgettable Moments"} />
			<meta property={"og:title"} content={"Riverside Anglers Retreat"} />
			<meta property={"og:description"} content={"Crafted Libations, Unforgettable Moments"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65773594fdcae00021dadf60/images/close-up-man-pouring-coffee-glass%20%281%29.jpg?v=2023-12-11T18:26:16.280Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11:19:35.970Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11:19:35.970Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11:19:35.970Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11:19:35.970Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11:19:35.970Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-3.jpg?v=2024-05-31T11:19:35.970Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="overrideOverride5" />
			<Override slot="overrideOverride6" />
			<Override slot="overrideOverride7" />
			<Override slot="overrideOverride8" />
			<Override slot="text" />
		</Components.Header>
		<Section padding="100px 0 100px 0" md-padding="80px 0 90px 0" quarkly-title="Hero-10">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
				sm-align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline1"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					md-font="normal 700 42px/1.2 --fontFamily-sansTrebuchet"
				>
					Ласкаво просимо до Riverside
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="--dark"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
					md-font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				>
					Riverside Anglers Retreat пропонує першокласний досвід риболовлі, забезпечуючи спокійну обстановку, де як початківці, так і завзяті рибалки можуть віддатися своїй пристрасті до риболовлі.
				</Text>
				<Button
					margin="0px 0 20px 0px"
					padding="14px 35px 14px 35px"
					background="--color-darkL1"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="#3f24d8"
					hover-color="--darkL1"
					hover-background="rgba(63, 36, 216, 0)"
					sm-width="100%"
					href="/about"
				>
					Про нас та послуги
				</Button>
			</Box>
			<Image
				src="https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/1-1.jpg?v=2024-05-31T11:19:35.978Z"
				display="block"
				height="600px"
				object-fit="cover"
				border-radius="35px"
				md-height="400px"
				sm-height="300px"
				object-position="top"
				srcSet="https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-1.jpg?v=2024-05-31T11%3A19%3A35.978Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-1.jpg?v=2024-05-31T11%3A19%3A35.978Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-1.jpg?v=2024-05-31T11%3A19%3A35.978Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-1.jpg?v=2024-05-31T11%3A19%3A35.978Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-1.jpg?v=2024-05-31T11%3A19%3A35.978Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-1.jpg?v=2024-05-31T11%3A19%3A35.978Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/1-1.jpg?v=2024-05-31T11%3A19%3A35.978Z&quality=85&w=3200 3200w"
				sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
			/>
		</Section>
		<Section
			padding="0 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--darkL1"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Чому Riverside?
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
						Різноманітні середовища проживання
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						{" "}наше унікальне річкове середовище дозволяє використовувати різноманітні техніки та досвід риболовлі.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Громадські заходи
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						беріть участь у наших регулярних змаганнях з риболовлі та громадських зборах.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
						Зусилля щодо збереження
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
						ми беремо активну участь у збереженні річок, щоб забезпечити процвітаючу екосистему для майбутніх поколінь.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="--color-lightD2" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text
							margin="0px 0px 15px 0px"
							font="--headline2"
							text-align="left"
							lg-font="normal 600 36px/1.2 --fontFamily-sans"
							color="--light"
						>
							Про наш відпочинок
						</Text>
						<Text
							margin="0px 0px 50px 0px"
							font="--base"
							lg-margin="0px 0px 40px 0px"
							sm-margin="0px 0px 30px 0px"
							color="--light"
						>
							Riverside Anglers Retreat прагне запропонувати неперевершену рибальську подорож. Наші річки багаті різноманітними видами риб, тому кожне відвідування стає новою пригодою. Від необхідного рибальського спорядження до експертних консультацій і обслуговування човнів, ми гарантуємо, що ваш досвід риболовлі буде чимось іншим, як надзвичайним.
						</Text>
					</Box>
					<Image
						src="https://uploads.quarkly.io/6659a1176bbd2000236b1369/images/2-2.jpg?v=2024-05-31T11:19:35.980Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-2.jpg?v=2024-05-31T11%3A19%3A35.980Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-2.jpg?v=2024-05-31T11%3A19%3A35.980Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-2.jpg?v=2024-05-31T11%3A19%3A35.980Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-2.jpg?v=2024-05-31T11%3A19%3A35.980Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-2.jpg?v=2024-05-31T11%3A19%3A35.980Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-2.jpg?v=2024-05-31T11%3A19%3A35.980Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6659a1176bbd2000236b1369/images/2-2.jpg?v=2024-05-31T11%3A19%3A35.980Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Components.NewFooter1>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
		</Components.NewFooter1>
	</Theme>;
});